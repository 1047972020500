// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-input .p-inputtext {
    width: 100%;
    height: 35px;
  }
  
  /* .schoolform .p-datatable .p-datatable-tbody > tr > th {
    padding: 12px 0px 0px;
  } */
  .schoolform .p-datatable .p-datatable-tbody > tr > td {
    padding: 0px 15px;
  }
  .personal-modal {
    width: 40% !important;
  }
  
  @media only screen and (max-width: 768px) {
    .personal-modal {
      width: 93% !important;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/AddEducationDetails/School.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;EACd;;EAEA;;KAEG;EACH;IACE,iBAAiB;EACnB;EACA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,qBAAqB;IACvB;EACF","sourcesContent":[".table-input .p-inputtext {\n    width: 100%;\n    height: 35px;\n  }\n  \n  /* .schoolform .p-datatable .p-datatable-tbody > tr > th {\n    padding: 12px 0px 0px;\n  } */\n  .schoolform .p-datatable .p-datatable-tbody > tr > td {\n    padding: 0px 15px;\n  }\n  .personal-modal {\n    width: 40% !important;\n  }\n  \n  @media only screen and (max-width: 768px) {\n    .personal-modal {\n      width: 93% !important;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
