// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.09);
  border: none;
  padding: 25px 30px;
  border-radius: 18px;
}

.texts h5 {
  font-weight: 500;
  font-size: 20px;
}

.line {
  margin: 10px 0px;
}

p {
  font-weight: 500;
}
p span {
  color: #8f8f8f;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/vehicles.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".card {\n  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.09);\n  border: none;\n  padding: 25px 30px;\n  border-radius: 18px;\n}\n\n.texts h5 {\n  font-weight: 500;\n  font-size: 20px;\n}\n\n.line {\n  margin: 10px 0px;\n}\n\np {\n  font-weight: 500;\n}\np span {\n  color: #8f8f8f;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
