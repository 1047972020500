// in a file named apiConfig.js

// const Nodeapi = 'http://localhost:8001/api';
// const ResumeApi = 'http://localhost:8001/assets/UserResume/';
// const ProfileImgApi = 'http://localhost:8001/assets/UserProfileImage/';
// const authapi = 'http://localhost:8001';
// module.exports = {
//   Nodeapi,
//   ResumeApi,
//   authapi,
//   ProfileImgApi,
  
// };


const Nodeapi = 'https://www.kodukku.com/api';
const ResumeApi = 'https://www.kodukku.com/assets/UserResume/';
const ProfileImgApi = 'https://www.kodukku.com/assets/UserProfileImage/';
const authapi = 'https://ktest.kodukku.com';
module.exports = {
  Nodeapi,
  ResumeApi,
  authapi,
  ProfileImgApi,
  
};

