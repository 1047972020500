// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MyTable.css */
.GeneratedTable {
    width: 100%;
    background-color: #ffffff;
    border-collapse: collapse;
    border-width: 2px;
    border-color: #1877f2;
    border-style: solid;
    color: #000000;
  }
  
  .GeneratedTable td,
  .GeneratedTable th {
    border-width: 2px;
    border-color: #1877f2;
    border-style: solid;
    padding: 3px;
  }
  
  .GeneratedTable thead {
    background-color: #1877f2;
  }
  `, "",{"version":3,"sources":["webpack://./src/Styles/HtmlTable.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;;IAEE,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* MyTable.css */\n.GeneratedTable {\n    width: 100%;\n    background-color: #ffffff;\n    border-collapse: collapse;\n    border-width: 2px;\n    border-color: #1877f2;\n    border-style: solid;\n    color: #000000;\n  }\n  \n  .GeneratedTable td,\n  .GeneratedTable th {\n    border-width: 2px;\n    border-color: #1877f2;\n    border-style: solid;\n    padding: 3px;\n  }\n  \n  .GeneratedTable thead {\n    background-color: #1877f2;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
