// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/mark.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    width: 28px;
    height: 28px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

    .has-error::after {
        content: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Replace 'path/to/error-icon.png' with your actual path */
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .has-error {
        position: relative;
      }`, "",{"version":3,"sources":["webpack://./src/auth/ResetComponent/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,gCAAgC;IAChC,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,sCAAsC;IACtC;;IAEA;IACA;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;IACA;;IAEA;QACI,gDAA4C,EAAE,2DAA2D;QACzG,kBAAkB;QAClB,WAAW;QACX,QAAQ;QACR,2BAA2B;MAC7B;MACA;QACE,kBAAkB;MACpB","sourcesContent":[".loader {\n    width: 28px;\n    height: 28px;\n    border: 5px solid #FFF;\n    border-bottom-color: transparent;\n    border-radius: 50%;\n    display: inline-block;\n    box-sizing: border-box;\n    animation: rotation 1s linear infinite;\n    }\n\n    @keyframes rotation {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n    } \n\n    .has-error::after {\n        content: url('../../assets/images/mark.png'); /* Replace 'path/to/error-icon.png' with your actual path */\n        position: absolute;\n        right: 10px;\n        top: 50%;\n        transform: translateY(-50%);\n      }\n      .has-error {\n        position: relative;\n      }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
