// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.09);
  border: none;
  padding: 25px 30px;
  border-radius: 18px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 2px;
}
h5 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 8px;
}
p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 1.5rem;
}
p span {
  color: #8f8f8f;
  font-weight: 400;
}
h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  h5 {
    font-size: 22px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/job.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;AACA;;;;;;EAME,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".card {\n  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.09);\n  border: none;\n  padding: 25px 30px;\n  border-radius: 18px;\n}\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin-bottom: 2px;\n}\nh5 {\n  font-weight: 700;\n  font-size: 24px;\n  margin-bottom: 8px;\n}\np {\n  font-weight: 500;\n  font-size: 14px;\n  margin-bottom: 0px;\n  line-height: 1.5rem;\n}\np span {\n  color: #8f8f8f;\n  font-weight: 400;\n}\nh6 {\n  font-size: 1.2rem;\n  font-weight: 600;\n}\n\n@media screen and (max-width: 768px) {\n  h5 {\n    font-size: 22px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
