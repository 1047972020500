// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.img-under{
    width: 40rem;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.text-kod{
    font-style: 90px;
}

@media only screen and (max-width: 768px) {
    .img-under{
        width: 15rem;
    }
    .text-kod{
        font-size: 60px;
    }
    .Maintain{
        margin-top: 60px !important;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/layouts/Maintainence.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,eAAe;IACnB;IACA;QACI,2BAA2B;IAC/B;;AAEJ","sourcesContent":["\n.img-under{\n    width: 40rem;\n    display: flex;\n    justify-content: center;\n    padding-top: 20px;\n}\n\n.text-kod{\n    font-style: 90px;\n}\n\n@media only screen and (max-width: 768px) {\n    .img-under{\n        width: 15rem;\n    }\n    .text-kod{\n        font-size: 60px;\n    }\n    .Maintain{\n        margin-top: 60px !important;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
