import React from "react";

const TermsPolicy = () => {
  return (
    <div>
      <div className="card">
        <h3 style={{color:"#2196F3"}}>Terms & Policy</h3>
        <p className="mt-3">
        By accessing and utilizing our platform, you are consenting to adhere to a comprehensive set of terms and policies designed to govern your interactions and engagements within our ecosystem. 
        Our platform functions as an integrated marketplace, housing a diverse array of functionalities within the digital marketing and advertising sphere. 
        We are committed to fostering a transparent, secure, and user-friendly environment that 
        facilitates seamless transactions and connections for all users.
        </p>
        <p className="mt-3">
        To participate effectively within our platform, users are required to meet specific eligibility criteria and adhere to registration procedures. 
        It is imperative that all provided information is accurate and up-to-date, and users must refrain from engaging in prohibited activities such as the sale of counterfeit 
        items or the dissemination of misleading listings of any kind. We retain the right to terminate or suspend accounts in the event of
         non-compliance or violation of our terms and policies to uphold the integrity of our community.
        </p>
        <p className="mt-3">
        Clear and comprehensive guidelines have been established to govern various aspects of our platform, including the listing of all kinds of advertising and marketing materials. 
        In the event of disputes, we provide accessible and effective resolution mechanisms to safeguard the interests of all parties involved.
        </p>
        <p className="mt-3">
        Intellectual property rights are respected and protected within our platform, with users retaining ownership of their uploaded content. 
        We have established procedures for reporting copyright or trademark infringements, 
        enabling swift action to address any violations and uphold the rights of content creators.
        </p>
        <p className="mt-3">
        Privacy and data security are of paramount importance to us. We are committed to responsibly collecting, using, and safeguarding 
        personal information provided by our users. Stringent data security measures are in place to protect against unauthorized access, use, or disclosure of personal data. 
        Users are provided with clear information regarding the collection and use of their personal information, 
        and consent mechanisms are in place to allow users to control the sharing of their data with other users.
        </p>
        <p className="mt-3">
        While we strive to maintain the highest standards of service and functionality, 
        it is important to note that liability is limited for platform owners, and warranties are 
        disclaimed to the fullest extent permitted by law. However, we remain committed to addressing user 
        concerns and resolving disputes in a fair and equitable manner.
        </p>
        <p className="mt-3">
        Our terms and policies are governed by applicable state and federal laws of India, and provisions are in place to establish jurisdiction for resolving disputes. 
        This ensures that our users can engage with confidence, knowing that their rights and interests are protected within a legal framework that promotes fairness and accountability. 
        Kindly contact us for further information about our company.
        </p>
      </div>

      <div className="card" style={{marginTop:"27px"}}>
        <h3 style={{color:"#2196F3"}}>Cancellation & Refund Policy </h3>
        <p className="mt-3">
        KODUKKU CLASSIFIEDS PRIVATE LIMITED believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy: 
        </p>
        <p className="mt-3">
        • Cancellations will be considered only if the request is made immediately after placing the order. 
          However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them. 
        </p>
        <p className="mt-3">
        • KODUKKU CLASSIFIEDS PRIVATE LIMITED does not accept cancellation requests for perishable items like flowers, eatables etc. 
          However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good. 
        </p>
        <p className="mt-3">
         • In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however,
           be entertained once the merchant has checked and determined the same at his own end. This should be reported within 7 Days days of receipt of the products. 
           In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 Days days of receiving the product. 
           The Customer Service Team after looking into your complaint will take an appropriate decision.
        </p>
        <p className="mt-3">
        • In case of complaints regarding products that come with a warranty from manufacturers, 
          please refer the issue to them. In case of any Refunds approved by the KODUKKU CLASSIFIEDS PRIVATE LIMITED, 
          it’ll take 9-15 Days days for the refund to be processed to the end customer.
        </p>
        {/* <p className="mt-3">
        While we strive to maintain the highest standards of service and functionality, 
        it is important to note that liability is limited for platform owners, and warranties are 
        disclaimed to the fullest extent permitted by law. However, we remain committed to addressing user 
        concerns and resolving disputes in a fair and equitable manner.
        </p>
        <p className="mt-3">
        Our terms and policies are governed by applicable state and federal laws of India, and provisions are in place to establish jurisdiction for resolving disputes. 
        This ensures that our users can engage with confidence, knowing that their rights and interests are protected within a legal framework that promotes fairness and accountability. 
        Kindly contact us for further information about our company.
        </p> */}
      </div>
    </div>
  );
};

export default TermsPolicy;
