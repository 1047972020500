// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expand .p-datatable .p-datatable-tbody > tr > td {
    padding: 3px 16px;
  }
  .expand .p-datatable .p-datatable-thead > tr > th {
    padding: 3px 16px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/Profile/RelationDetails.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB","sourcesContent":[".expand .p-datatable .p-datatable-tbody > tr > td {\n    padding: 3px 16px;\n  }\n  .expand .p-datatable .p-datatable-thead > tr > th {\n    padding: 3px 16px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
