// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global Styles */
.Sidebar {
  width: 210px;
  height: 100vh;
  background: #1877f2;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px;
  color: white;
}
.main{
  display: flex;
}

/* Sidebar Logo */
.logo {
  width: 40px;
  background: white;
  padding: 4px;
  border-radius: 4px;
}

/* Sidebar Icons */
.icon {
  font-size: 20px;
}

/* Top Section Icons */
.ts-icons {
  font-size: 20px;
  margin-left: 15px;
}

/* Individual Icon */
.ts-icon {
  margin: 0px 5px;
}

@media screen and (max-width:768px) {
  .sidebar{
      position: fixed;
      top: 0;
      right: -100%;
      transition: right 0.4s;
      z-index: 9;
  }
  
  .main {
    display: block; /* or any other value you prefer for mobile devices */
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/SideBar/Side.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB;gFAC8E;EAC9E,YAAY;AACd;AACA;EACE,aAAa;AACf;;AAEA,iBAAiB;AACjB;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;;AAEA,kBAAkB;AAClB;EACE,eAAe;AACjB;;AAEA,sBAAsB;AACtB;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA,oBAAoB;AACpB;EACE,eAAe;AACjB;;AAEA;EACE;MACI,eAAe;MACf,MAAM;MACN,YAAY;MACZ,sBAAsB;MACtB,UAAU;EACd;;EAEA;IACE,cAAc,EAAE,qDAAqD;EACvE;AACF","sourcesContent":["/* Global Styles */\n.Sidebar {\n  width: 210px;\n  height: 100vh;\n  background: #1877f2;\n  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 5px -3px,\n    rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px;\n  color: white;\n}\n.main{\n  display: flex;\n}\n\n/* Sidebar Logo */\n.logo {\n  width: 40px;\n  background: white;\n  padding: 4px;\n  border-radius: 4px;\n}\n\n/* Sidebar Icons */\n.icon {\n  font-size: 20px;\n}\n\n/* Top Section Icons */\n.ts-icons {\n  font-size: 20px;\n  margin-left: 15px;\n}\n\n/* Individual Icon */\n.ts-icon {\n  margin: 0px 5px;\n}\n\n@media screen and (max-width:768px) {\n  .sidebar{\n      position: fixed;\n      top: 0;\n      right: -100%;\n      transition: right 0.4s;\n      z-index: 9;\n  }\n  \n  .main {\n    display: block; /* or any other value you prefer for mobile devices */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
