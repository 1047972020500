
import { MdAdd } from "react-icons/md";

import { useSelector } from "react-redux";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

import React, { useState } from "react";

import { useEffect } from "react";

import { Dropdown } from "primereact/dropdown";

import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";

import { InputText } from "primereact/inputtext";

import { ProductService } from "../../Data/ProductService";

import "../../Component/AddEducationDetails/School.css";

import "./RelationDetails.css";

import axios from "axios";

import PhoneInput from "react-phone-input-2";

import { Nodeapi } from "../../config/serverUrl";

import { Calendar } from "primereact/calendar";

 

const style = {

  position: "absolute",

  top: "50%",

  left: "50%",

  transform: "translate(-50%, -50%)",

  width: "500px",

  backgroundColor: "#fff",

  border: "0px",

  boxShadow:

    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",

  borderRadius: "12px",

  padding: "32px",

  height: "483px",

  overflowY: "scroll",

};

 

const RelationshipDetails = () => {

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [phone, setphone] = useState("");

  console.log(phone,"phone")

  //handleDob

  const calculateAge = (DOB) => {

    const today = new Date();

    const birthDate = new Date(DOB);

    let calculatedAge = today.getFullYear() - birthDate.getFullYear();

    const month = today.getMonth() - birthDate.getMonth();

 

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {

      calculatedAge--;

    }

    if (calculatedAge < 0 || calculateAge > 120) {

      return "invalid age";

    }

 

    return calculatedAge;

  };

 

  useEffect(() => {

    ProductService.getProductsMini().then((data) => setProducts(data));

  }, []);

 

  const relationships = [

    { name: "Father", value: "father" },

    { name: "Mother", value: "mother" },

    { name: "Son", value: "son" },

    { name: "Daughter", value: "daughter" },

    { name: "Brother", value: "brother" },

    { name: "Grandmother / Grandma", value: "grandmother" },

    { name: "Grandfather / Grandpa", value: "grandfather" },

    { name: "Grandchild", value: "grandchild" },

    { name: "Aunt", value: "aunt" },

    { name: "Uncle", value: "uncle" },

    { name: "Niece", value: "niece" },

    { name: "Nephew", value: "nephew" },

    { name: "First Cousin", value: "first cousin" },

    { name: "Second Cousin", value: "second cousin" },

    { name: "Mother-in-law", value: "mother in law" },

    { name: "Father-in-law", value: "father in law" },

    { name: "Brother-in-law", value: "brother in law" },

    { name: "Sister-in-law", value: "sister in law" },

    { name: "Wife", value: "wife" },

    { name: "Stepmother / Stepmom", value: "stepmother" },

    { name: "Stepfather / Stepdad", value: "stepfather" },

    { name: "Stepchild", value: "stepchild" },

    { name: "Guardian", value: "guardian" },

    { name: "Ward", value: "ward" },

  ];

 

  //const [selectedValue, setSelectedValue] = useState('');

 

  const DeleteButton = ({ rowIndex, handleDelete }) => {

    const handleClick = () => {

      handleDelete(rowIndex);

    };

 

    return (

      <div className="">

        <Button onClick={handleClick}>

          <i

            className="fi fi-rr-trash"

            style={{

              color: "red",

              background: "#ffc0c0",

              fontSize: "21px",

              padding: "4px 10px 0px 10px",

              borderRadius: "14%",

            }}

          />

        </Button>

      </div>

    );

  };

 

  const [relationForm, setRelationForm] = useState({

    userid: "",

    firstname: "",

    fathername: "",

    familyname: "",

    DOB: "",

    age: "",

    phonenumber: "",

    occupation: "",

    relation: "",

  });

 

  const [products, setProducts] = useState([]);

 

  const handleButtonClick = () => {

    const newProduct = {

      id: Date.now(),

      firstname: "",

      // Lastname: "",

      fathername: "",

      familyname: "",

      DOB: "",

      age: "",

      phonenumber: "",

      occupation: "",

      relation: "",

    };

    handleClose();

    setProducts([...products, newProduct]);

  };

 

  // const [selectedRelationship, setSelectedRelationship] = useState('');

  console.log("relationForm", relationForm);

 

  const authdata = useSelector((state) => state.auth.user);

  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setRelationForm({

      ...relationForm,

      [name]: value,

      userid: authdata?.id,

    });

  };

 

  const handleRelationshipChange = (e) => {

    setRelationForm({

      ...relationForm,

      relation: e.target.value,

    });

  };

 

  const handleDateChange = (event) => {

    const newDob = event.target.value;

    // Call AgeChange function with the new date of birth

    setRelationForm({

      ...relationForm,

      DOB: newDob,

      age: calculateAge(newDob),

    });

  };

 

  //handlephone number

 

  console.log("phone:", phone);

 

  // const AgeChange = (newDob) => {

  //   const calculatedAge = calculateAge(newDob);

  //   // Update the state with the calculated age

  //   setRelationForm({

  //     ...relationForm,

  //     age: calculatedAge

  //   });

  // };

 

  console.log(relationForm);

  const [relationdata, setRelationData] = useState([]);

 

  //

 

  const handlemobileno = (value) => {

    setphone(value);

    setRelationForm((prevstate) => ({

      ...prevstate,

      phonenumber: phone,

    }));

  };

  //ADDrelation

 

  const addrelationsdata = async (e) => {

    e.preventDefault();

 

    const action = e.target.value;

    if (action === "Submit") {

      const res = await axios.post(`${Nodeapi}/addRelationdetails`, {

        relationdata: relationForm,

        userid: authdata?.id,

      });

      if (res.data) {

        if (res.data.code == 200) {

          handleClose();

          setRelationData(res.data.data.result);

          console.log(res.data.data.message);

          setRelationForm("");

        } else {

          console.log(res.data.data.message);

        }

      }

    } else {

      console.log("update function");

      const res = await axios.put(`${Nodeapi}/updateData/${relationForm.id}`, {

        updaterelationdta: relationForm,

        userid: relationForm.userid,

      });

 

      console.log(res);

      if (res.data) {

        if (res.data.code == 200) {

          handleClose();

          setRelationData(res.data.data.result);

          console.log(res.data.data.result);

        } else {

          console.log(res.data.data.message);

        }

      }

    }

  };

 

  //fetchD

 

  useEffect(() => {

    fetchRelationInformation();

  }, []);

 

  const fetchRelationInformation = async () => {

    const res = await axios.get(`${Nodeapi}/fetchdata?id=${authdata?.id}`);

    console.log(res.data.data.result);

    setRelationData(res.data.data.result);

 

    if (res.data) {

      if (res.data.code == 200) {

        console.log(res.data.data.result);

      } else {

        console.log(res.data.data.message);

      }

    }

  };

 

  const deleteRelationData = async (id) => {

    try {

      await axios.delete(`${Nodeapi}//deleteData/${id}`);

      // After successful deletion, fetch updated data

      fetchRelationInformation();

      console.log("Relation data deleted successfully");

    } catch (error) {

      console.error("Error deleting relation data:", error);

    }

  };

  // const handelEdits = (value, field, rowData) => {

  //   const updateRelation = relationForm.map((product) =>

  //     product === rowData  ? { ...product, [field]: value } : product

  //   );

  //   setRelationForm(updateRelation);

  // }

 

  const [buttonvalue, setButtonValu] = useState("");

  console.log(buttonvalue,"button");

  const actions = (rowData) => {

    const handleEdit = (rowData) => {

      // Handle edit action with rowData

      handleOpen();

      console.log("Edit clicked for:", rowData);

      setRelationForm(rowData);

      setButtonValu("update");

    };

    const handleDelete = async (rowData) => {

      // Handle delete action with rowData

      if (window.confirm("Are you sure you want to delete this data?")) {

        try {

          await deleteRelationData(rowData.id);

          console.log("Delete clicked for:", rowData);

        } catch (error) {

          console.error("Error deleting relation data:", error);

        }

      }

    };

 

    // const handleDeleteButton = (rowIndex) => {

    //   const updatedProducts = products.filter((_, index) => index !== rowIndex);

    //   setProducts(updatedProducts);

    // };

 

    return (

      <div className="">

        <div className="flex">

          <button

            className="me-2"

            style={{

              background: "#ff00004a",

              color: "red",

              border: "none",

              borderRadius: "4px",

              padding: "4px 6px 0px",

            }}

            onClick={() => handleDelete(rowData)}

          >

            <i className="fi fi-rr-trash"></i>

          </button>

          <button

            data-id={rowData}

            className=""

            style={{

              background: "#72abff8f",

              color: "#0d6efd",

              border: "none",

              borderRadius: "4px",

              padding: "4px 6px 0px",

            }}

            onClick={() => handleEdit(rowData)}

          >

            <i className="fi fi-rr-pencil"></i>

          </button>

        </div>

      </div>

    );

  };

 

  const handleOpenform = async () => {

    setButtonValu("Submit");

    handleOpen();

    setRelationForm({

      userid: "",

      firstname: "",

      fathername: "",

      familyname: "",

      DOB: "",

      age: "",

      phonenumber: "",

      occupation: "",

      relation: "",

    });

  };

 

  return (

    <div>

      <div className="card mt-4">

        <div className="d-flex justify-content-between">

          <h5>Relationship Details</h5>

          <p>

            <Button onClick={handleOpenform}>

              <MdAdd size={22} />

            </Button>

            <Modal

              open={open}

              onClose={handleClose}

              aria-labelledby="modal-modal-title"

              aria-describedby="modal-modal-description"

            >

              <Box sx={style}>

                <h6 htmlFor="" className="mb-2">

                  Relationship Details :

                </h6>

                <div className="col-12">

                  <label htmlFor="" className="mb-1 labels">

                    Firstname

                  </label>

                  <input

                    type="text"

                    name="firstname"

                    className="form-control"

                    placeholder="Firstname"

                    id=""

                    value={relationForm.firstname}

                    onChange={handleInputChange}

                  />

                </div>

 

                <div className="col-12">

                  <label htmlFor="" className="mb-1 labels">

                    Fathername

                  </label>

                  <input

                    type="text"

                    name="fathername"

                    className="form-control"

                    placeholder="Fathername"

                    id=""

                    value={relationForm.fathername}

                    onChange={handleInputChange}

                  />

                </div>

                <div className="col-12">

                  <label htmlFor="" className="mb-1 labels">

                    Familyname

                  </label>

                  <input

                    type="text"

                    name="familyname"

                    className="form-control"

                    placeholder="Familyname"

                    id=""

                    value={relationForm.familyname}

                    onChange={handleInputChange}

                  />

                </div>

                <div className="col-12">

                  <label htmlFor="" className="mb-1 labels">

                    Dob

                  </label>

                  <input

                    type="date"

                    className="form-control"

                    placeholder="Date of birth"

                    value={relationForm.DOB}

                    onChange={handleDateChange}

                    name="DOB"

                  />

                  {/* <Calendar

                    id="dob"

                    className="datebirth w-100"

                    showIcon={false}

                    // onChange={handlesignup}

                    name="dateOfBirth"

                    monthNavigator

                    placeholder="Date of birth"

                    yearNavigator

                    yearRange="1990:2024"

                    dateFormat="dd/mm/yy"

                    inputId="in"

                    style={{

                      height: "43px",

                      borderTopLeftRadius: "0px",

                      borderBottomLeftRadius: "0px",

                      borderTopRightRadius: "4px",

                      borderBottomRightRadius: "4px",

                      border: "none",

                    }} // Set the width to 300px

                  /> */}

                </div>

                <div className="col-12">

                  <label htmlFor="" className="mb-1 labels">

                    Age

                  </label>

                  <input

                    type="text"

                    name="age"

                    className="form-control"

                    placeholder="Age"

                    id=""

                    value={relationForm.age}

                    readOnly

                  />

                </div>

 

                <div className="col-12">

                  <label htmlFor="" className="mb-1 labels">

                    Phonenumber

                  </label>

                  <PhoneInput

                    country={"in"}

                    value={phone}

                    onChange={handlemobileno}

                    className="border-example "

                  />

                </div>

 

                <div className="col-12">

                  <label htmlFor="" className="mb-1 labels">

                    Occupation

                  </label>

                  <input

                    type="text"

                    name="occupation"

                    className="form-control"

                    placeholder="occupation"

                    id=""

                    onChange={handleInputChange}

                    value={relationForm.occupation}

                  />

                </div>

 

                <div className="col-12">

                  <label htmlFor="" className="mb-1 labels">

                    Relation

                  </label>

                  <select

                    className="form-control"

                    id="relationship"

                    name="relation"

                    value={relationForm.relation}

                    onChange={handleRelationshipChange}

                  >

                    <option value="" disabled>

                      Select Relationship

                    </option>

                    <option value="father">father</option>

                    <option value="mother">mother</option>

                    <option value="son">son</option>

                    <option value="daughter">Daughter</option>

                    <option value="brother">brother</option>

                    <option value="grandmother">Grandmother / Grandma</option>

                    <option value="grandfather">Grandfather / Grandpa</option>

                    <option value="grandchild">Grandchild</option>

                    <option value="aunt">Aunt</option>

                    <option value="uncle">uncle</option>

                    <option value="niece">Niece</option>

                    <option value="nephew">Nephew</option>

                    <option value="first cousin">First Cousin</option>

                    <option value="Second cousin">Second Cousin</option>

                    <option value="mother in law">mother-in-law</option>

                    <option value="father in law">Father-in-law</option>

                    <option value="brother in law">Brother-in-law</option>

                    <option value="sister in law">Sister-in-law</option>

                    <option value="wife">Wife</option>

                    <option value="stepmother">Stepmother / Stepmom</option>

                    <option value="stepfather">Stepfather / Stepdad</option>

                    <option value="stepchild">Stepchild</option>

                    <option value="guardian">Guardian</option>

                    <option value="ward">Ward</option>

                  </select>

                </div>

 

                <div className="text-center mt-2">

                  <button className="btn btn-danger me-2" onClick={handleClose}>

                    Cancel

                  </button>

 

                  <button

                    type="submit"

                    value={buttonvalue}

                    className="btn btn-primary"

                    onClick={addrelationsdata}

                  >

                    {buttonvalue}

                  </button>

                </div>

              </Box>

            </Modal>

          </p>

        </div>

        <div className="expand">

          <DataTable value={relationdata} tableStyle={{ minWidth: "50rem" }}>

            <Column field="firstname" header="FirstName"></Column>

 

            <Column field="fathername" header="FatherName"></Column>

            <Column field="familyname" header="FamilyName"></Column>

            <Column field="DOB" header="DOB"></Column>

            <Column field="age" header="Age"></Column>

            <Column field="phonenumber" header="Phonenumber"></Column>

            <Column field="occupation" header="Occupation"></Column>

            <Column field="relation" header="Relation"></Column>

            <Column

              body={(rowData) => actions(rowData)}

              header="Actions"

            ></Column>

          </DataTable>

        </div>

      </div>

    </div>

  );

};

 

export default RelationshipDetails;

 

 

 

